
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="编辑调拨单"
    :visible.sync="visible"
    width="1000px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        
        <el-col :span="24">
                <el-form-item label="调出仓库" prop="fromWarehouseName">
              <el-input v-model="row.fromWarehouseName" placeholder="请输入调出仓库" clearable
                :disabled="true"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="调入仓库" prop="toWarehouseName">
              <el-input v-model="row.toWarehouseName" placeholder="请输入调入仓库" clearable
                :disabled="true"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="调拨类型" prop="type">
                <el-select v-model="row.type"
                :disabled="false"
                clearable
                placeholder="请选择调拨类型">
                    <el-option
                          v-for="item in enums['WmsTransferTypeEnum']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                </el-select>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                      </el-col>
                      </el-row>

                      <el-row>
                      <el-col>

                <el-form-item label="调拨明细" prop="details">

<vxe-table
        :data="row.details"
        empty-text=""
        :scroll-y="{enabled: false}"
        :edit-config="{trigger: 'click', mode: 'cell'}"
        ref="detailsTable"
>

            <vxe-column field="id" title="ID" :visible="false" ></vxe-column>







        <vxe-column field="skuName" title="货品" >
            <template slot-scope="scope">
                        <span>{{scope.row.skuName}}</span>
            </template>
        </vxe-column>


        <vxe-column field="skuSpec" title="规格" >
            <template slot-scope="scope">
                        <span>{{scope.row.skuSpec}}</span>
            </template>
        </vxe-column>


        <vxe-column field="skuCode" title="货品编码" >
            <template slot-scope="scope">
                        <span>{{scope.row.skuCode}}</span>
            </template>
        </vxe-column>


        <vxe-column field="unitId" title="调拨单位" >
            <template slot-scope="scope">
                    <el-select ref="unitId"
                               :disabled="false"
                               v-model="scope.row.unitId"
                               prop="unitId"
                               placeholder="请选择调拨单位"
                               clearable
                               filterable
                               :filter-method="listWmsUnit"
                               :style="{width: '100px'}"
                    >
                        <el-option
                                v-for="item in optionsOfWmsUnit"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
            </template>
        </vxe-column>




        <vxe-column field="quantity" title="数量" >
            <template slot-scope="scope">
                    <el-input-number v-model="scope.row.quantity"
                                     :disabled="false"
                                     placeholder="请输入数量" clearable
                                     :controls="false"
                                     :step="1"
                                     :style="{width: '100px'}"></el-input-number>
            </template>
        </vxe-column>


        <vxe-column field="fromBinId" title="调出货位" >
            <template slot-scope="scope">
                    <el-select ref="fromBinId"
                               :disabled="false"
                               v-model="scope.row.fromBinId"
                               prop="fromBinId"
                               placeholder="请选择调出货位"
                               clearable
                               filterable
                               :filter-method="listWmsBinLocation"
                               :style="{width: '120px'}"
                    >
                        <el-option
                                v-for="item in optionsOfWmsBinLocation"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
            </template>
        </vxe-column>




        <vxe-column field="toBinId" title="调入库位" >
            <template slot-scope="scope">
                    <el-select ref="toBinId"
                               :disabled="false"
                               v-model="scope.row.toBinId"
                               prop="toBinId"
                               placeholder="请选择调入库位"
                               clearable
                               filterable
                               :filter-method="listWmsBinLocation"
                               :style="{width: '120px'}"
                    >
                        <el-option
                                v-for="item in optionsOfWmsBinLocation"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
            </template>
        </vxe-column>














</vxe-table>                </el-form-item>
                            </el-col>
                            </el-row>

                            <el-col>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="备注" prop="remark">
              <el-input v-model="row.remark" placeholder="请输入备注"
                :disabled="false"
                    clearable
                    type="textarea"
                    :rows="3"
                    :style="{width: '200px'}"
                ></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { editWmsTransfer } from "@/api/wms/wmsTransfer"

import { listWmsWarehouse } from "@/api/wms/wmsWarehouse"


import { listWmsSku } from "@/api/wms/wmsSku"
import { listWmsUnit } from "@/api/wms/wmsUnit"
import { listWmsBinLocation } from "@/api/wms/wmsBinLocation"

export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            fromWarehouseId: null,
            fromWarehouseName: null,
            toWarehouseId: null,
            toWarehouseName: null,
            type: null,
            details: null,
            status: null,
            remark: null,
            loginUserId: null,
            loginUserName: null,
      },
      rules: {
            fromWarehouseId: [
                { required: true, message: '请输入调出仓库!'},
            ],
            toWarehouseId: [
                { required: true, message: '请输入调入仓库!'},
            ],
            type: [
                { required: true, message: '请输入调拨类型!'},
            ],
            status: [
                { required: true, message: '请输入状态!'},
            ],
      },
        enums: {},
        enumMaps: {},
        optionsOfWmsWarehouse: [],


        optionsOfWmsSku: [],
        optionsOfWmsUnit: [],
        optionsOfWmsBinLocation: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
    this.listWmsWarehouse()


    this.listWmsSku()
    this.listWmsUnit()
    this.listWmsBinLocation()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsTransferTypeEnum')
          names.push('WmsTransferStatus')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen(row) {
        this.visible = true
        this.row = row
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },
    listWmsWarehouse(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsWarehouse(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsWarehouse = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },


    listWmsSku(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSku(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSku = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsUnit(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsUnit(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsUnit = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsBinLocation(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsBinLocation(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsBinLocation = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        editWmsTransfer(param).then(res=>{
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
