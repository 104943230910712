import request from '@/api/request.js'

/**
 * @Description: 调拨单
 */

// 分页查询
export function pageWmsTransfer(data) {
    return request({
        url: '/api/wmsTransfer/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsTransfer(data) {
    return request({
        url: '/api/wmsTransfer/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsTransfer(data) {
    return request({
        url: '/api/wmsTransfer/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsTransfer(data) {
    return request({
        url: '/api/wmsTransfer/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsTransfer(data) {
    return request({
        url: '/api/wmsTransfer/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsTransfer(data) {
    return request({
        url: '/api/wmsTransfer/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsTransfer(data) {
    return request({
        url: '/api/wmsTransfer/batchDelete',
        method: 'POST',
        data
    })
}

